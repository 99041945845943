module.exports = [{
      plugin: require('/builds/hatchet/softwarewagon/node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/hatchet/softwarewagon/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:400"]}},
    },{
      plugin: require('/builds/hatchet/softwarewagon/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/hatchet/softwarewagon/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137588689-1","head":true},
    },{
      plugin: require('/builds/hatchet/softwarewagon/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
